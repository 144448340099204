<template>
  <div>
    <v-autocomplete
      v-model="selectedTeams"
      class="ta-team-selector"
      :label="label"
      :items="computedTeams"
      item-text="name"
      item-value="id"
      :return-object="returnObject"
      :rules="rules"
      multiple
      chips
      :search-input.sync="teamSearch"
      :prepend-icon="prependIcon"
      :messages="messages"
      :hint="hint"
      :persistent-hint="persistentHint"
      :error-messages="errorMessages"
      :disabled="disabled"
      @change="onChange"
    >
      <template #selection="data">
        <v-chip pill>
          <v-avatar width="50px !important" height="40px !important" left>
            <team-profile-photos :urls="data.item.profilePhotos" />
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>

      <template v-if="canCreateNewTeams" #prepend-item>
        <v-list-item @click="addTeamDialog = true">
          <v-list-item-avatar width="50px" height="20px">
            <v-icon color="primary">fa-plus</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t('addTeam') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template #item="{ item, attrs }">
        <v-list-item-avatar width="50px">
          <team-profile-photos :urls="item.profilePhotos" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ item.memberCount }} {{ item.memberCount === 1 ? $t('member') : $t('members') }}
            <span v-if="item.managed"
              ><v-icon class="pa-1">fa-id-badge</v-icon> {{ $t('managed') }}</span
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="showIds">ID: {{ item.id }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon :key="item.id + attrs.inputValue">
          <template v-if="!teamDisabled(item)">
            <v-icon v-if="attrs.inputValue" color="primary">fa-check-square</v-icon>
            <v-icon v-else>far fa-square</v-icon>
          </template>
        </v-list-item-icon>
      </template>

      <template v-if="clearable && selectedTeams.length > 0" #append>
        <v-btn
          icon
          @click.stop="
            selectedTeams = [];
            onChange();
          "
          ><v-icon class="pa-1">fa-times</v-icon></v-btn
        >
      </template>
    </v-autocomplete>

    <add-team-dialog
      v-model="addTeamDialog"
      :members="members"
      @team-added="($event) => teamAdded($event)"
    />
  </div>
</template>

<script>
import TeamProfilePhotos from './TeamProfilePhotos.vue';
import AddTeamDialog from './AddTeamDialog.vue';
import api from '@/util/api';

export default {
  name: 'TeamSelector',

  components: {
    TeamProfilePhotos,
    AddTeamDialog,
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },

    members: {
      type: Array,
      required: false,
      default: () => [],
    },

    prependIcon: {
      type: String,
      required: false,
      default: null,
    },

    showIds: {
      type: Boolean,
      required: false,
      default: false,
    },

    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },

    rules: {
      type: Array,
      required: false,
      default: () => [],
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    messages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    hint: {
      type: String,
      required: false,
      default: undefined,
    },

    persistentHint: {
      type: Boolean,
      required: false,
      default: false,
    },

    errorMessages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },

    teamDisabled: {
      type: Function,
      required: false,
      default: () => false,
    },

    canCreateNewTeams: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    teamSearch: '',
    teams: [],
    selectedTeams: [],
    addTeamDialog: null,
  }),

  computed: {
    computedTeams() {
      return this.teams.map((team) => ({
        ...team,
        disabled: this.teamDisabled(team),
      }));
    },
  },

  watch: {
    value() {
      this.selectedTeams = this.value;
    },
  },

  created() {
    this.loadTeams();
    this.selectedTeams = this.value;
  },

  methods: {
    onChange() {
      this.teamSearch = '';
      this.$emit('input', this.selectedTeams);
    },

    teamAdded(event) {
      this.loadTeams().then(() => {
        const newTeam = this.teams.find((team) => team.id === event.teamId);
        if (newTeam) {
          this.selectedTeams.push(this.returnObject ? newTeam : newTeam.id);
        }
      });
    },

    loadTeams() {
      return api.getTeams(3).then((res) => {
        this.teams = res.teams;
        this.$emit('teams-loaded', this.teams);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ta-team-selector ::v-deep .v-select__selections {
  max-height: 300px;
  overflow: auto;
}
</style>
